<template>
  <div class="container">
    <el-container>
      <el-aside class="aside" style="background-color: #545c64">
        <el-menu active-text-color="#ffd04b" background-color="#5b6874" class="el-menu-vertical-demo" default-active="2" text-color="#fff">
          <el-sub-menu style="background-color: #545c64;" v-for="(menu, index) in data.menus" :key="menu.label" :index="index + 1 + ''" v-show="!menu.hidden">
            <template #title>
              <span>{{ menu.label }}</span>
            </template>
            <el-menu-item v-for="(page, index2) in menu.pages" :index="index + '-' + index2" :key="page.path" @click="router.push('/' + page.path)">{{ page.label }}</el-menu-item>
          </el-sub-menu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header class="header" style="display: flex; justify-content: space-between;">
          <div style="display: flex; justify-content:flex-start;">
            <div style="margin-right: 20px;">
              <el-text>XueSiNuo的个人中心</el-text>
            </div>
          </div>
          <div style="display: flex; justify-content:flex-end;">
            <div style="margin-right: 20px;">
              <el-link type="primary" href="https://beian.miit.gov.cn">冀ICP备2021001249号</el-link>
            </div>
          </div>
        </el-header>
        <el-main class="main">
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
import menusConf from '../router/menusConf'

const data = reactive({
  menus: [],
})
data.menus = menusConf.filter(false)
</script>

<style lang="less">
.container {
  .aside {
    width: 250px;
    height: 100vh;
  }

  .header {
    height: 49px;
    line-height: 49px;
    overflow: hidden;
    border-bottom: 1px solid #eee;
  }

  .main {
    height: calc(100vh - 55px);
    width: calc(100vw - 270px);
  }

  /deep/ .el-menu {
    background-color: #5b6874;
  }

  /deep/ .el-submenu .el-menu {
    background-color: #283847;
  }
}
</style>